.vote {
  display: flex;
  width: 100%;
}

.photo {
  height: 100px;
  width: 150px;
  overflow: hidden;
  margin-bottom: 5px;
  position: relative;
}

.photoCaption {
  position: absolute;
  bottom: 0;
  height: 20px;
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
}

.photo img {
  position: relative;
  width: 300px;
  left: -50%;
}

.photo--2023 img {
  width: 180px;
  left: 0;
}

.photo--homepage.photo--2023 img {
  width: 300px;
}

.rank {
  position: absolute;
  top: 0;
  left: 0;
}

.holder {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: auto;
}

.rankedWrapper {
  margin: 0 auto;
  padding: 0 20px 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.rankedWrapper h2 {
  margin-top: 0;
  text-align: center;
}

.ranked {
  min-height: 500px;
  padding: 20px;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons {
  padding: 20px;
}

.buttons button {
  margin-left: 20px;
}

.userName {
  padding: 20px;
}

.userName input {
  display: block;
}
