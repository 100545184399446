.big-board {
  width: 600px;
  display: flex;
  flex-direction: column;
  margin: 0 10vw 0 auto;
}

.placeholders {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.placeholder {
  height: 220px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.placeholder h2 {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
  font-size: 36px;
  left: 20px;
  top: -6px;
}

.placeholder img {
  max-width: 300px;
  position: absolute;
  top: 0;
  width: 100%;
}

.photo--homepage {
  width: 275px;
  height: 185px;
  padding-bottom: 20px;
}

.photo--homepage img {
  width: 550px;
}

.photo--homepage .photoCaption {
  height: 40px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.voters {
  padding: 20px 60px;
  width: 250px;
}

.voters--text {
  font-size: 14px;
  margin-bottom: 20px;
}

.homepage-row {
  display: flex;
  flex-direction: column;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

@media (min-width: 800px) {
  .home-wrapper {
    display: flex;
  }
  .homepage-row {
    flex-direction: row;
  }
}
